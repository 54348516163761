<template>
  <!-- 公司详情 -->
  <div id="app" style="background: #f4f4f4">
    <div class="cson-container">
      <div class="face-banner">
        <img v-if="companyDetail.cover" :src="companyDetail.cover" alt="" />
        <img v-else src="@/assets/company/face.png" alt="" />
        <div class="face-banner-content">
          <div class="face-banner-bd">
            <div class="face-logo">
              <img :src="companyDetail.logo" />
            </div>
            <div class="face-text">
              <div class="face-title">
                {{ companyDetail.name }}
              </div>
              <div class="face-info">
                <span class="face-adr">
                  {{ companyDetail.companyAddress }}</span
                >
                <span class="face-kf">{{ companyDetail.mainBusiness }}</span>
                <span class="face-val"
                  >${{ companyDetail.marketCapitalization }}</span
                >
              </div>
            </div>
          </div>
          <div class="face-right">
            <el-button size="small" class="follow-btn" @click="openComment"
              >反馈</el-button
            >
          </div>
          <el-dialog
            title="反馈"
            :visible.sync="commentVisible"
            append-to-body
            :before-close="closeComment"
          >
            <el-input
              type="textarea"
              v-model="comment"
              rows="5"
              resize="none"
            ></el-input>
            <div slot="footer" style="text-align: center">
              <el-button @click="closeComment" class="gray">关闭</el-button>
              <el-button type="primary" class="warn" @click="publishComments"
                >提交</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <div class="company-warp">
        <el-tabs type="border-card" class="com-tabs">
          <el-tab-pane>
            <span slot="label" class="orange"
              ><i class="iconfont icon-info-1-copy"></i> 基本信息</span
            >
            <div class="company-panel">
              <el-row :gutter="18" style="margin-bottom: 12px">
                <el-col :span="18" style="min-height: 393px">
                  <div class="company-panel-hd" ref="companyPanel">
                    <el-row style="margin-bottom: 10px">
                      <el-col :span="16">
                        <div class="company-group">
                          <div class="company-group-label">公司官网：</div>
                          <div class="company-group-val">
                            {{ companyDetail.officialWebsite }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="company-group">
                          <div class="company-group-label">总部：</div>
                          <div class="company-group-val">
                            {{ companyDetail.companyAddress }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="16">
                        <div class="company-group">
                          <div class="company-group-label">招聘官网：</div>
                          <div class="company-group-val">
                            {{ companyDetail.recruitingWebsite }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="company-group">
                          <div class="company-group-label">成立时间：</div>
                          <div class="company-group-val">
                            {{ companyDetail.establishmentDate }}
                          </div>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="company-group">
                          <div class="company-group-label">市值：</div>
                          <div class="company-group-val">
                            ${{ companyDetail.marketCapitalization }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="company-group">
                          <div class="company-group-label">规模：</div>
                          <div class="company-group-val">
                            {{ companyDetail.scale }}
                          </div>
                        </div>
                      </el-col>
                      <el-col
                        :span="24"
                        v-if="
                          companyDetail.toTheCompanys &&
                          companyDetail.toTheCompanys.length > 0
                        "
                      >
                        <div class="company-group">
                          <div class="company-group-label">对标公司：</div>
                          <div class="company-group-val">
                            <router-link
                              v-for="(
                                img, index
                              ) in companyDetail.toTheCompanys"
                              :key="index"
                              :to="`/companyDetail?id=${img.id}`"
                            >
                              <img style="width: 30px" alt="" :src="img.logo"
                            /></router-link>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <div class="company-group">
                          <div class="company-group-label">主营业务：</div>
                          <div class="company-group-val">
                            {{ companyDetail.mainBusiness }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <div class="job-info">
                          <p>
                            <span>内推的内部消息：</span
                            >{{ companyDetail.insideInformation }}
                          </p>
                          <p>
                            <span>内推流程：</span
                            >{{ companyDetail.pushProcess }}
                          </p>
                        </div>
                        <div class="job-info">
                          <p>
                            <span>招聘方式的有效性：</span
                            ><span class="orange">{{
                              companyDetail.effective
                            }}</span>
                          </p>
                          <p>
                            <span>特殊说明：</span
                            >{{ companyDetail.specialInstructions }}
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8"> </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="6">
                  <el-row>
                    <div class="live-group">
                      <div class="live-group-tit">直播预告</div>
                      <div class="live-group-content">
                        <div class="live-main-content">三种刷题语言的选择</div>
                        <div class="live-tips">直播时长:</div>
                        <div class="live-tips">直播时间:</div>
                        <el-button class="live-button">点击进入</el-button>
                      </div>
                    </div>
                  </el-row>
                  <el-row
                    ><div class="job-group" :style="{ height: qrcodeHeight }">
                      <div class="job-group-tit">
                        {{ companyDetail.name }}求职群
                      </div>
                      <div class="job-group-ewm">
                        <img src="../../assets/company/job.png" />
                      </div></div
                  ></el-row>
                </el-col>
              </el-row>

              <div class="company-panel-bd">
                <el-row :gutter="10" v-if="companyDetail.companyRecruitments">
                  <el-col :span="12">
                    <div
                      class="company-card"
                      v-if="companyDetail.companyRecruitments[0]"
                    >
                      <div class="company-card-hd">
                        <div class="company-card-tt">
                          Internship <span>（参考往届数据）</span>
                        </div>
                      </div>
                      <div class="company-card-bd">
                        <el-row :gutter="46">
                          <el-col :span="14">
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  预计的开放时间：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .scheduledTime
                                  }}
                                </div>
                              </div>
                            </div>
                            <!-- <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  预计的开放时间：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  <span>夏季：3-8月</span>
                                  <span>冬季：9-12月</span>
                                </div>
                              </div>
                            </div> -->
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试流程：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .flowPath
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试内容：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[0].content
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试说明：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .illustrate
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  Return Offer：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .returnOffer
                                  }}
                                </div>
                              </div>
                            </div>
                          </el-col>
                          <el-col :span="10">
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  实习薪资（平均）
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .salaryPackage
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  预计的名额数量：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[0]
                                      .expectedPlaces
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">CPT：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[0].cpt
                                      ? "提供"
                                      : "不提供"
                                  }}
                                </div>
                              </div>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div
                      class="company-card"
                      v-if="companyDetail.companyRecruitments[1]"
                    >
                      <div class="company-card-hd">
                        <div class="company-card-tt">
                          New Grad <span>（参考往届数据）</span>
                        </div>
                      </div>
                      <div class="company-card-bd">
                        <el-row :gutter="46">
                          <el-col :span="14">
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  预计的开放时间：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .scheduledTime
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试流程：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .flowPath
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试内容：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[1].content
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">面试说明：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .illustrate
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">绿卡政策：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .greenCard
                                  }}
                                </div>
                              </div>
                            </div>
                          </el-col>
                          <el-col :span="10">
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  全职薪资（平均）
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .salaryPackage
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">
                                  预计的名额数量：
                                </div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[1]
                                      .expectedPlaces
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="company-cell">
                              <div class="company-cell-hd">
                                <div class="company-cell-label">H1B：</div>
                              </div>
                              <div class="company-cell-bd">
                                <div class="company-cell-val orange">
                                  {{
                                    companyDetail.companyRecruitments[1].h1b
                                      ? "sponsor"
                                      : "no sponsor"
                                  }}
                                </div>
                              </div>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label" class="blue"
              ><i class="iconfont icon-offering"></i> 面经</span
            >
            <interviewExp :companyId="companyId" type="company"></interviewExp>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import interviewExp from "../interview/interviewExp";
import { getCompanyDetail, publishAdvice } from "@/service/company";
export default {
  data() {
    return {
      companyId: "",
      companyDetail: {},
      commentVisible: false,
      comment: "",
      qrcodeHeight: "200px",
    };
  },
  components: { interviewExp },
  methods: {
    getCompanyDetail() {
      getCompanyDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.companyDetail = res.result;
          document.title = `${this.companyDetail.name} - 专注北美算法面试 - CSON`;
        }
      });
    },
    openComment() {
      this.commentVisible = true;
    },
    closeComment() {
      this.comment = "";
      this.commentVisible = false;
    },
    publishComments() {
      if (this.comment == "") {
        return this.$message.error("反馈不能为空！");
      }
      publishAdvice({
        companyId: this.$route.query.id,
        content: this.comment,
      }).then((res) => {
        if (res.success) {
          this.$message.success("已收到您的反馈，我们会尽快处理");
          this.closeComment();
        }
      });
    },
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.companyId = this.$route.query.id;
      }
    },
    companyId() {
      this.getCompanyDetail();
    },
    companyDetail(val) {
      if (val) {
        console.log(this.$refs.companyPanel.offsetHeight);
        this.qrcodeHeight = this.$refs.companyPanel.offsetHeight - 220 + "px";
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.companyId = this.$route.query.id;
    }
    this.getCompanyDetail();
  },
};
</script>

<style lang="css" scoped>
.face-banner {
  position: relative;
  height: 233px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.face-banner-content {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 40px 15px 80px;
}

.follow-btn {
  color: #fa6400;
  border: 1px solid #fa6400;
  border-radius: 4px;
  font-size: 18px;
  width: 120px;
  margin-top: 10px;
}

.face-banner-bd {
  flex: 1;
  display: flex;
}

.face-logo {
  width: 100px;
  margin-right: 20px;
  font-size: 0;
}
.face-logo img {
  width: 100px;
  height: 100px;
}

.face-title {
  font-size: 18px;
  color: #fff;
  margin-top: 3px;
}

.face-info {
  display: flex;
  align-items: center;
}

.face-info span {
  color: #fff;
  font-size: 16px;
  margin-right: 30px;
  min-width: 80px;
}

.face-info .face-val {
  font-size: 24px;
  color: #fa6400;
}

.company-warp {
  padding: 10px 0;
}

.com-tabs {
  background-color: transparent;
  border: 0;
  box-shadow: unset;
}

.com-tabs ::v-deep .el-tabs__nav {
  float: none;
  display: flex;
}

.com-tabs ::v-deep .el-tabs__item {
  margin-right: 15px;
  flex: 1;
  text-align: center;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin: 0 !important;
  margin-right: 15px !important;
}

.com-tabs ::v-deep .el-tabs__item:last-child {
  margin-right: 0 !important;
}

.com-tabs ::v-deep .el-tabs__header {
  border-bottom: 0;
  background-color: transparent;
  margin-bottom: 10px;
}
.com-tabs ::v-deep .el-tabs__item:first-child {
  border-color: #fa6400;
  background-color: #fff;
}

.com-tabs ::v-deep .el-tabs__item:nth-child(2) {
  border-color: #2772db;
  background-color: #fff;
}

.com-tabs ::v-deep .el-tabs__item.is-active:first-child {
  background-color: rgba(250, 100, 0, 0.1);
  border-color: #fa6400;
}

.com-tabs ::v-deep .el-tabs__item.is-active:nth-child(2) {
  background-color: rgba(39, 114, 219, 0.1);
  border-color: #2772db;
}

.com-tabs ::v-deep .el-tabs__content {
  padding: 0;
}
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}

.company-panel-hd {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 0 40px 55px;
  min-height: 393px;
}

.company-group {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.company-group-label {
  color: #34495e;
  margin-right: 12px;
}

.company-group-val {
  color: #2772db;
}

.company-group-val img {
  margin-right: 2px;
  vertical-align: middle;
}

.job-group,
.live-group {
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  text-align: center;
  padding: 0;
  display: inline-block;
  padding: 3px 17px 7px;
}
.live-group {
  text-align: left;
  padding: 24px;
  margin-bottom: 8px;
}
.job-group img {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}

.job-group-tit {
  color: #34495e;
  font-size: 15px;
  line-height: 24px;
}

.job-group-ewm {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}

.job-info {
  font-size: 14px;
  color: #34495e;
  line-height: 28px;
  margin-bottom: 20px;
}

.job-info span {
  margin-right: 4px;
}

.company-card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0 15px 30px;
  background-color: #fff;
}

.company-card-hd {
  padding-left: 15px;
  padding-right: 20px;
  position: relative;
  margin-bottom: 15px;
}

.company-card-hd::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
}

.company-card-tt {
  font-size: 16px;
  color: #34495e;
  line-height: 20px;
}

.company-card-tt span {
  color: #898989;
  font-size: 12px;
}

.company-cell {
  display: flex;
  line-height: 14px;
  margin-bottom: 12px;
}

.company-cell-bd {
  flex: 1;
}

.company-cell-label {
  font-size: 14px;
  color: #34495e;
}

.company-cell-val {
  font-size: 12px;
  color: #34495e;
}

.company-cell-val span {
  margin-right: 25px;
}

.formInline-cell {
  background-color: #fff;
  padding: 10px 50px;
  margin-bottom: 6px;
}

.formInline-cell .el-input,
.formInline-cell .el-select {
  margin-right: 20px;
  font-size: 12px;
}

.formInline-cell .el-select ::v-deep .el-input__inner {
  padding-left: 8px;
}

.formInline-cell ::v-deep .el-icon-arrow-up:before {
  content: "\e78f";
}

.sort-btns {
  position: relative;
}

.sort-btns::after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  width: 1px;
  height: 12px;
  background-color: #34495e;
}

.sort-btns .el-button--text {
  color: #34495e;
  padding: 0 4px;
}

.sort-btns .el-button:first-child {
  padding-left: 0;
}

.sort-btns .el-button:last-child {
  padding-right: 0;
}

.ms-content {
  width: 890px;
  float: left;
}

.ms-aside {
  width: 300px;
  float: right;
}

.aside-course {
  font-size: 0;
}

.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}

.ms-type {
  font-size: 12px;
  color: #34495e;
}

.ms-cell {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 24px 20px 20px 50px;
}

.ms-type span {
  padding: 0 5px;
}

.ms-type span:first-child {
  padding-left: 0;
}

.ms-user {
  display: flex;
  align-items: center;
}

.ms-user-name {
  color: #0075f6;
  font-size: 12px;
  text-decoration: underline;
  padding: 0 5px;
}

.ms-user-time {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
}

.ms-user-avatar {
  font-size: 0;
}

.ms-cell-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.ms-cell-tt {
  color: #34495e;
  font-size: 14px;
  margin-bottom: 4px;
}

.ms-cell-tt .el-tag {
  border-color: #b3f2ff;
  background-color: #b3f2ff;
  font-size: 12px;
  color: #34495e;
  border-radius: 0;
  padding: 0 6px;
  margin-left: 12px;
}

.ms-cell-desc {
  font-size: 12px;
  color: #828282;
}

.ms-cell-data .el-button--text .iconfont {
  font-size: 12px;
  color: #34495e;
}
.ms-cell-data .el-button--text {
  font-size: 12px;
  color: #34495e;
  padding: 0;
}

.ms-cell-data .el-button + .el-button {
  margin-left: 20px;
}

.ms-cell-ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-block {
  text-align: center;
  padding: 20px 0;
}

.pagination-block .el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 40px;
}

.pagination-block .el-pagination ::v-deep .el-pager li {
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: normal;
}

.pagination-block .el-pagination ::v-deep .el-pager {
  padding: 0 30px;
}

.pagination-block .el-pagination ::v-deep .first {
  font-weight: normal;
  position: absolute;
  left: 0;
}

.pagination-block .el-pagination ::v-deep .last {
  font-weight: normal;
  position: absolute;
  right: 0;
}

.pagination-block .el-pagination ::v-deep button,
.pagination-block .el-pagination ::v-deep span {
  min-width: 36px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
}

.pagination-block .el-pagination ::v-deep button {
  margin: 0 25px;
}

.ms-piece span {
  font-size: 14px;
  color: #34495e;
  padding: 0 5px;
}

.ms-piece span:first-child {
  padding-left: 0;
}

i {
  font-style: normal;
}

.offer-cell {
  padding: 28px 30px 32px 50px;
}

.offer-cell .ms-cell-hd {
  margin-bottom: 10px;
}

.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}
::v-deep .expTab .el-tabs__header {
  line-height: 48px;
  background: #fff;
  color: #34495e;
  font-size: 16px;
}
::v-deep .expTab .el-tabs__nav-scroll {
  padding: 0 42px;
}
::v-deep .expTab .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .expTab .el-tabs__active-bar {
  background-color: #fa6400;
  border-radius: 2px;
  height: 4px;
}
::v-deep .expTab .el-tabs__item:hover,
::v-deep .expTab .el-tabs__item.is-active {
  color: #34495e;
}
.filterTab {
  padding: 16px 42px;
  background: #fff;
  margin-bottom: 8px;
}
.filterTab .el-dropdown {
  margin: 0 6px;
  margin-right: 24px;
}
.live-group-tit {
  padding-left: 15px;
  padding-right: 20px;
  position: relative;
  margin-bottom: 12px;
}
.live-group-tit::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
}
.live-button {
  background: #fff;
  border: 1px solid #fa6400;
  color: #fa6400 !important;
  font-size: 14px;
  width: 100%;
}
.live-main-content {
  color: #34495e;
  font-size: 14px;
  line-height: 30px;
}
.live-tips {
  color: #34495e;
  font-size: 12px;
  line-height: 30px;
}
.warn {
  background-color: #fa6400 !important;
  color: #fff !important;
}
.gray {
  background-color: #909399 !important;
  color: #fff !important;
}
.el-dialog {
  border-radius: 8px;
}
</style>
